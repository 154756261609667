export const DEFAULT_MAIN_TITLE = 'Save on replacing or repairing your windows';

export const SECOND_MAIN_TITLE = `No you can't get free windows, but...`;

export const DEFAULT_MAIN_SUBTITLE =
  'Find out how much you can save in just 30 seconds!';

export const EXIT_PAGE_MESSAGE =
  'Are you sure you want to leave? There is so much more to explore!';

export const ZIP_CODE_ERROR_MESSAGE = 'Please enter a valid ZIP code';

export const RADIO_ERROR_MESSAGE = 'Please select an option';

export const FIRST_NAME_ERROR_MESSAGE = 'Please enter first name';

export const WINDOWS_COUNT_ERROR_MESSAGE = 'Please select the count of windows';

export const PROJECT_TYPE_ERROR_MESSAGE =
  'Please select a window project option';

export const DROPDOWN_ERROR_MESSAGE = 'Please select an option';

export const LAST_NAME_ERROR_MESSAGE = 'Please enter last name';

export const ADDRESS_ERROR_MESSAGE = 'Please enter Street Address';

export const ADDRESS_PATTERN_ERROR_MESSAGE =
  'Please do not put your email address in this field';

export const CITY_ERROR_MESSAGE = 'Please enter a city';

export const STATE_ERROR_MESSAGE = 'Please select a State';

export const EMAIL_ERROR_MESSAGE = 'Please enter a valid Email';

export const EMAIL_DOMAIN_ERROR_MESSAGE = 'Please verify your email domain';

export const PHONE_ERROR_MESSAGE =
  'Please enter a valid phone number in the format (xxx) xxx-xxxx';

export const PATTERN_PHONE_ERROR_MESSAGE =
  'Please do not start a phone number with 0 or 1';

export const TOLL_FREE_PHONE_ERROR_MESSAGE =
  'Toll free area codes are not allowed';

export const CONSECUTIVE_PHONE_NUMBER_ERROR_MESSAGE =
  'Please input a valid phone number';

export const NETWORK_ERROR = 'Network Response was not Ok';

export const LOCAL_PHONE_NUMBER = '877-621-3085';
