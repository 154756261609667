import {useState, useRef} from 'react';
import DisclaimerDropDown from '../../../CustomComponents/DisclaimerDropDown/DisclaimerDropDown';
function DesignYesNo({lenderList, selectedList, onSelect, onSubmit}) {
  const [error, setError] = useState(false)
  const projectTypeRef = useRef();

  const submitList = () => {
    if (!selectedList.length) {
      setError(true);
    }else {
      setError(false);
      onSubmit();
    }
  }

  const selectBuyer = (name) => {
    if (selectedList.includes(name)) {
      onSelect({target: {checked:false, value: name}});
    }else {
      onSelect({target: {checked:true, value: name}});
    }
  }

  return (
    <div className="form-step3">
      <div className="form-step">
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <DisclaimerDropDown
          projectTypeRef={projectTypeRef}
        />
        <div style={{ fontSize: '13px', marginTop: '10px', color: '#777777' }}>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          By clicking "Get Your Matches" I am providing my electronic signature expressly
          agreeing that the company or companies selected below, may contact me
          for marketing purposes, including through use of an automated telephone
          dialing system, pre-recorded or Al generated
          voice, and/or SMS/MMS, and I agree to be contacted via telephone by
          the
          <span style={{ textDecoration: 'underline' }}>
            companies listed here
          </span>
          even if my telephone number is on a state, federal or corporate Do Not
          Call list; and I also agree to the Privacy Policy and Terms of Use,
          including the requirement to Arbitration of all disputes, and that
          GetWindowsToday.com can share my personal data with third-parties. I
          understand my consent is not a condition of receiving services and
          that I may instead email something@rgrmarketing.com.
        </div>
        <div
          style={{ fontSize: '14px', marginTop: '10px', marginBottom: '10px' }}
        >
          Keep all options elected for he best quotes!
        </div>
        <div
          style={{
            marginTop: '10px',
            display: 'grid',
            placeContent: 'start',
            marginBottom: '10px',
          }}
        >
          {lenderList.map((item, key) => (
            <div className="labelDesign2" key={key} onClick={() => {setError(false);selectBuyer(item.name);}}>
              <div className='checkboxDesign2'>
                {selectedList.includes(item.name) ?
                  <div className="checkboxDesign2Yes">Yes</div>
                  :
                  <div className="checkboxDesign2No">Select</div>
                }
              </div>
              <div className='buyerNameDesign2'>{item.name}</div>
            </div>
          ))}
        </div>
        {error &&
          <div style={{marginTop: '5px', marginBottom: '5px', color: 'red'}}>
            You must select a minimum of one partner to get your quote. Selecting all partners is recommended.
          </div>
        }
        <button type="button" onClick={submitList} className="custom-submit__btn">
          Get Your Matches
        </button>
      </div>
    </div>
  );
}

export default DesignYesNo;
