import { useFormContext } from "react-hook-form";
import { DISCLAIMER_OPTIONS, DROPDOWN_ERROR_MESSAGE } from "../../../lib/constants";
import { useState } from "react";
import CustomSelect from "../CustomSelect/CustomSelect";

export default function DisclaimerDropDown({projectTypeRef}) {
  const { register, formState } = useFormContext();
  const { errors } = formState;
  const [dropOption, setDropOption] = useState('');

  return (
    <div className="disclaimer-dropdown">
      <p>How soon do you want to start your project?</p>
      <CustomSelect
        options={DISCLAIMER_OPTIONS}
        name="starttype"
        value={dropOption}
        onChange={(e) => setDropOption(e)}
        register={register}
        customRef={projectTypeRef}
      />
    </div>
  )
}
